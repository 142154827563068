/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */

// import '@angular/localize/init';
/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */
// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;
/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'core-js/features/array';
import 'core-js/features/object';
import 'core-js/features/promise';
import 'core-js/features/regexp';
import 'core-js/features/url-search-params';
// https://k3nsei.gitbook.io/ng-in-viewport/getting-started/import-the-modules
import 'intersection-observer';
import 'map.prototype.tojson';
import 'reflect-metadata';
/***************************************************************************************************
 * APPLICATION IMPORTS
 */
// eslint-disable-next-line
import 'zone.js';
